.announcement-new {
  @apply bg-blue-100 text-blue-600;
}

.announcement-fix {
  @apply bg-red-100 text-red-600;
}

.announcement-update {
  @apply bg-green-100 text-green-600;
}

.announcement-improvement {
  @apply bg-purple-100 text-purple-600;
}

.unread-announcements span:before {
  @apply inline-block w-2 h-2 mr-1.5 rounded-full bg-red-500 content-[''];
}
