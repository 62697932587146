.nav-link {
  text-transform: uppercase;
  font-size: 12px !important;

  @apply block no-underline text-gray-500 hover:text-gray-800 px-2 py-1 text-base font-medium whitespace-nowrap;
  line-height: 30px;

  &.active {
    @apply font-semibold;
  }

  &:focus {
    @apply text-gray-900;
  }
  color: white;
}

.nav-link.active {
  font-weight: 600;
  border-bottom: 2px solid yellow;
  margin-bottom: -9px;
  padding-bottom: 9px;
  text-decoration: none !important;
  color: white !important;
}
.nav-link:hover{
  font-weight: 600;
  border-bottom: 2px solid yellow !important;
  margin-bottom: -9px;
  padding-bottom: 9px;
  text-decoration: none !important;
  color: white !important;
}

.nav_title{
  text-transform: capitalize;
    font-size: 20px !important;
}

@media (min-width: 1024px) {
  .nav-link {
    @apply inline-flex my-0 mr-3;
    line-height: 40px;
  }
}

/* Highlights unread notifications with background color */
[data-notifications-target="notification"]:not([data-read-at]) {
  @apply bg-gray-100;
}

/* Highlights uninteracted notifications with blue dot */
[data-notifications-target="notification"]:not([data-interacted-at]) {
  [uninteracted] {
    @apply block;
  }
}

.dropdown-menu {
  min-width: 100%;

  @screen lg {
    min-width: 200px;
  }
}
