@import "tailwindcss/base";
@import "tailwindcss/components";

/*
 * Jumpstart Pro default styles
 * Remove these if you'd like to use TailwindUI which expects a clean slate
*/
@import "components/base";
@import "components/actiontext";
@import "components/alert";
@import "components/animation";
@import "components/announcements";
@import "components/avatars";
@import "components/buttons";
@import "components/code";
@import "components/connected_accounts";
@import "components/direct_uploads";
@import "components/docs";
@import "components/forms";
@import "components/icons";
@import "components/iframe";
@import "components/nav";
@import "components/pagination";
@import "components/tabs";
@import "components/trix";
@import "components/typography";
@import "components/util";

@import "tailwindcss/utilities";

@import "flatpickr/dist/flatpickr.css";
@import "tippy.js/dist/tippy.css";
@import "tom-select/dist/css/tom-select.css";
@import "custom.css";

.nav-link.active {
    font-weight: 600;
    color: #5BA4E5;
    text-decoration: underline;
}
#selectallrows:before{
    display: none;
}
#selectallrows:after{
    display: none;
}
#bulkactions {
    float: right;
    width: 10%;
}
.dataTables_length label {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 6px;
}

.dataTables_length select {
    width: 232px;
}

#example_length {
    width: 160px;
}

