.btn-primary {
    background-color: #D80C2E !important;
}
.match img {
    margin: auto;
}

.match {
    text-align: left;
}

.match .action-items {
    justify-content: center;
}

.btn-primary {
    background-color: #5BA4E5!important;
}

#match_search {
    width: 90%;
}

.btn-blue {
    background-color: #5BA4E5 !important;
}
/*.nav-link:hover {*/
/*    --tw-text-opacity: 1;*/
/*    color: rgb(53 59 63);*/
/*    background: #ffffff;*/
/*}*/
#affinity_bar > div.flex.items-center.flex-shrink-0.mr-6{
     background: #ffffff;
}

@media (min-width: 1024px) {
    .lg\:inline-flex {
        display: inline-flex !important;
    }
}
#affinity_bar > div.flex-grow.hidden.w-full.pb-6.lg\:flex.lg\:w-auto.lg\:pb-0 {
    position: absolute;
    display: contents;
}

body{
    font-family: Open Sans, sans-serif;
}

#status{
    width: 100px;
    font-size: 13px;
    height: 41px;
}