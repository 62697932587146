.icon-xs {
  width: 12px;
  height: auto;
}

.icon-sm {
  width: 16px;
  height: auto;
}

.icon {
  width: 20px;
  height: auto;
}

.icon-lg {
  width: 32px;
  height: auto;
}

.icon-xl {
  width: 36px;
  height: auto;
}

.icon-2xl {
  width: 48px;
  height: auto;
}

.icon-3xl {
  width: 64px;
  height: auto;
}

